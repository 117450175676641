// build non-private api url
export function nuApiUrl(apiUrl, isSSR) {
  if (typeof process != 'undefined' && process.env && process.env.MODE == 'test') {
    return process.env.NUXT_FRAPI_BASE_URL + apiUrl
  }

  let urlParts = []
  if (isSSR) {
    if (apiUrl == 'theme') {
      urlParts.push(useRuntimeConfig().themesApiBaseUrl + '/')
    } else {
      urlParts.push('/api/')
    }
  } else {
    urlParts.push(window.location.origin + '/api/')
  }

  urlParts.push(apiUrl)

  return urlParts.join('')
}
